import { useState, useEffect } from 'react';

import config from '../config';

export default function useGiveawayWidget() {
  const [show, setShow] = useState(false);

  useEffect(() => {
    function handleWindowScroll() {
      if (
        !window ||
        !window.scrollY ||
        !window.document ||
        !window.document.body ||
        !window.document.body.offsetHeight
      ) {
        return;
      }
      const percent = window.scrollY / window.document.body.offsetHeight;
      if (percent > 0.3 && show === false) {
        setShow(true);
      }
    }

    window.addEventListener('scroll', handleWindowScroll);
    return function() {
      window.removeEventListener('scroll', handleWindowScroll);
    };
  }, [show, setShow]);

  if (!config.backendActive || !config.enableBlogGiveaway) {
    return [false, () => {}];
  }

  return [show, setShow];
}
