import React, { Component } from 'react';
import classnames from 'classnames';

import Styled from 'styled-components';

class BeforeAfterImage extends Component {
  state = {
    isExpanded: false,
  };

  onWrapperClick = () => {
    this.setState({ isExpanded: false });
  };

  onClick = () => {
    this.setState(prevState => ({ isExpanded: !prevState.isExpanded }));
  };

  render() {
    const { image, thumbnail, children } = this.props;
    const { isExpanded } = this.state;

    const classes = classnames({
      'before-after-image': true,
      expanded: isExpanded,
    });

    return (
      <Wrapper className={classes} onClick={this.onClick}>
        <div className="before-after-image__close-message">
          Click anywhere to close
        </div>
        <div className="before-after-image__close-button">&times;</div>
        <div className="before-after-image__thumbnail">{thumbnail}</div>
        <div className="before-after-image__image">{image}</div>
        <div className="before-after-image__content">
          <h3>Before / After</h3>
          {children}
        </div>
      </Wrapper>
    );
  }
}

const Wrapper = Styled.div`
  width: 293px;
  border: 1px solid #ddd;
  border-radius: 4px;
  transition: all .2s ease-in-out;
  box-sizing: border-box;
  display: block;
  display: inline-block;
  padding: 4px;
  margin-bottom: 20px;
  line-height: 1.42857143;
  background-color: #fff;
  margin: 24px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;

  @media screen and (max-width: 340px) {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    overflow-x: hidden;
  }

  &:hover {
    box-shadow: 0 7px 14px rgba(0,0,0,0.25), 0 5px 5px rgba(0,0,0,0.22);
  }

  .before-after-image__close-button {
    display: none;
  }

  .before-after-image__close-message {
    display: none;
  }

  .before-after-image__thumbnail {
    margin: 0 auto;
  }

  .before-after-image__image {
    display: none;
  }

  .before-after-image__content {
    padding-left: 12px;
    padding-right: 12px;

    h3 {
      margin-bottom: 12px;
    }

    p {
      margin-top: 0;
    }
  }

  &.expanded {
    position: fixed;
    width: 100vw;
    height: calc(100vh - 50px);
    background-color: rgba(0, 0, 0, .95);
    top: 50px;
    left: 0;
    z-index: 10000;
    margin: 0;

    .before-after-image__thumbnail,  .before-after-image__image {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .before-after-image__image {
      display: block;
      width: 90%;
      max-height: 80%;
      z-index: 10000;
    }

    .before-after-image__content {
      display: none;
    }

    .before-after-image__close-button {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 6px;
      right: 6px;
      color: white;
      font-size: 36px;
      width: 30px;
      height: 30px;
    }

    .before-after-image__close-message {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 6px;
      left: 50%;
      transform: translateX(-50%);
      color: white;
      font-size: 16px;
      background-color: rgba(255, 255, 255, .2);
      padding: 3px 12px;
      border-radius: 10px;
    }
  }
`;

export default BeforeAfterImage;
