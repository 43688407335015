import Styled from 'styled-components';
import { fullWidthMixin } from '../mixins';

const Wrapper = Styled.div`
  padding: 98px 24px 0 24px;
  font-family: 'Roboto', sans-serif;
  
  * {
    box-sizing: border-box;
  }

  p, ul {
    font-size: 18px;
    line-height: 1.4;
  }

  h2 {
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1.2px;
  }

  section {
    margin-top: 96px;
    margin-bottom: 96px;

    &.compressed {
      max-width: 720px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .trust-badges {
    display: inline-flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .trust-badge {
      margin: 24px;
    }
  }

  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 24px;
    height: 50px;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: #FAFAFA;
    transition: all .4s ease;
    z-index: 100;

    .header__phone-number {
      height: 100%;
      a {
        height: 100%;
        background-color: #3676D9;
        background-color: #F0662F;
        color: #FFF !important;
        padding: 6px 18px;
        display: flex;
        box-sizing: border-box;
        align-items: center;
        justify-content: center;
        transition: all .3s ease;
        text-decoration: none;

        &:hover {
          background-color: #d35b32;
        }
      }
    }

    &.scrolled {
      box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.25);
    }
  }

  h1 {
    font-size: 48px;
    margin-top: 0;
  }

  .hero__one-column {
    display: none;
  }

  @media screen and (max-width: 640px) {
    .hero__two-column {
      display: none;
    }

    .hero__one-column {
      display: block;
    }
  }
`;

const HeroImage = Styled.div`
  max-width: 640px;
  min-width: 280px;
  border-radius: 50%;
  overflow: hidden;

  .gatsby-image-wrapper {
    height: 100%;
  }
`;

const TwoColumn = Styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-around;
  width: 100%;

  & > div {
    width: calc(50% - 48px);
    max-width: 720px;
  }
`;

const ThreeColumnCompare = Styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  max-width: 960px;
  margin: 24px auto 48px auto;
  box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.3);
  overflow-x: auto;

  & > div {
    width: calc(100% / 3 + 1px);
    max-width: 460px;
    min-width: 220px;
  }

  & > div:last-child {
    border-right: none;
  }

  & > div:nth-child(1) h4 {
    background-color: #39CCCC;
    color: white;
  }

  & > div:nth-child(2) h4 {
    background-color: #7FDBFF;
    color: white;
  }

  & > div:nth-child(3) h4 {
    background-color: #FF851B;
    color: white;
  }

  h4 {
    text-align: center;
    padding-top: 12px;
    padding-bottom: 12px;
    margin: 0;
    letter-spacing: 1.2px;
    text-shadow: 1px 1px rgba(0, 0, 0, .3);
    text-transform: uppercase;
  }

  ul {
    margin-top: 30px;
    margin-bottom: 30px;
    list-style-type: none;
    padding-left: 24px;
    padding-right: 24px;
  }

  li {
    margin-bottom: 24px;
  }

  @media screen and (max-width: 820px) {
    width: auto;
    max-width: auto;
    display: block;

    & > div {
      width: 100%;
      max-width: 100%;
    }
  }
`;

const InputWrapper = Styled.div`
  input {
    width: 100%;
    height: 40px;
    border-radius: 4px;
    display: block;
    border: none;
    padding-left: 12px;
    box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.3);
  }
`;

const CTAButton = Styled.button`
  max-width: 100%;
  min-width: ${({ fullWidth }) => (fullWidth ? '100%' : '240px')};
  height: ${({ fullWidth }) => (fullWidth ? '70px' : '50px')};
  font-size: 30px;
  color: white;
  background-color: #EF4E59;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 24px;
  margin-left: 0;
  cursor: pointer;
  transition: all .3s ease;
  border: none;

  &:hover {
    background-color: #EF5E69;
    box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.3);
  }

  &:disabled {
    opacity: 0.3;
  }
`;

const CTASecondaryButton = Styled.a`
  width: 240px;
  min-width: 240px;
  height: 50px;
  font-size: 30px;
  color: #EF4E59;
  border: 1px solid #EF4E59;
  background-color: white;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 24px;
  margin-left: 0;
  text-decoration: none;
  cursor: pointer;
  transition: all .3s ease;
  position: relative;

  &:hover {
    border: 1px solid #EF5E69;
    color: #EF5E69;
    box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.3);
  }
`;

const QuoteFormWrapper = Styled.div`
  background-color: ${({ bgColor }) => (bgColor ? bgColor : '#F6F2F2')};
  padding: 24px;
  border-radius: 4px;
  margin-top: 48px;

  h3 {
    display: ${({ showHeader }) => (showHeader ? 'block' : 'none')};
  }

  div {
    margin-bottom: 24px;
  }

  label {
    margin-bottom: 12px;
    display: block;
  }

  label span {
    margin-bottom: 6px;
    display: block;
  }

  .error-message {
    color: #FF3860;
  }

  select {
    background-color: #FFF;
    width: 100%;
    height: 40px;
    border-radius: 4px;
    display: block;
    border: none;
    padding-left: 12px;
    box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.3);
  }
`;

const TestimonalSection = Styled.section`
  ${fullWidthMixin};

  blockquote p {
    font-size: 18px;
    line-height: 1.4;
  }

  h2 {
    text-align: center;
  }

  .testimonial-section__testimonials {
    display: flex;
    overflow-x: auto;
  }

  .testimonial-card {
    width: 35%;
    min-width: 35%;
  }

  @media screen and (max-width: 960px) {
    .testimonial-card {
      width: 52%;
      min-width: 52%;
    }
  }

  @media screen and (max-width: 720px) {
    .testimonial-card {
      width: 70%;
      min-width: 70%;
    }
  }
`;

const BeforeAfterSection = Styled.section`
  ${fullWidthMixin};
  padding-left: 48px;
  padding-right: 48px;
  box-sizing: border-box;

  h2 {
    text-align: center;
  }

  .before-after-section__inner-wrapper {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    max-width: 100%;
    flex-wrap: wrap;
  }

  @media screen and (max-width: 776px) {
    .before-after-section__inner-wrapper {
      justify-content: center;
    }
  }

  @media screen and (max-width: 460px) {
    padding-left: 24px;
    padding-right: 24px;
  }
`;

export {
  Wrapper,
  HeroImage,
  TwoColumn,
  ThreeColumnCompare,
  InputWrapper,
  CTAButton,
  CTASecondaryButton,
  QuoteFormWrapper,
  TestimonalSection,
  BeforeAfterSection,
};
