import React from 'react';

import Styled from 'styled-components';

const TestimonialCard = ({ children }) => {
  return <Wrapper className="testimonial-card">{children}</Wrapper>;
};

const Wrapper = Styled.div`
  min-width: 15em;
  background: #fff;
  border-radius: 2px;
  padding: 2em 1em 1em 1em;
  display: inline-block;
  margin: 1rem;
  position: relative;
  width: 300px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  box-sizing: border-box;

  @media screen and (max-width: 720px) {
    padding: 24px 24px 12px 12px;

    blockquote {
      margin: 0;
    }
  }

  blockquote {
    border-left: 5px solid black;
    border-color: #EEEEEE;
    padding-left: 20px;
    margin-left: 0;

    p {
      font-size: 16px;
    }
  
    footer {
      font-style: italic;
      font-size: 14px;
  
      &:before {
        content: '\\2014 ';
      }
    }
  }
`;

export default TestimonialCard;
