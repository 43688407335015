import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { growl } from '../../redux/actions/notifications/actionCreators';

import Styled, { ThemeProvider } from 'styled-components';
import SVG from '../../primespot-ui/components/svg';
import Modal from '../../primespot-ui/components/modal';

import axios from 'axios';

import theme from '../../styles/theme';
import config from '../../config';

class GiveawayWidget extends React.Component {
  state = { formVisible: false };

  static propTypes = {
    visitorId: PropTypes.string,
    visible: PropTypes.bool,
    onClose: PropTypes.func,
  };

  submitContact = ({ email }) => {
    const { visitorId } = this.props;
    const preparedData = {
      email,
      visitorId,
    };

    axios
      .post(`${config.backendServer}/giveaway-contacts`, preparedData)
      .then(res => {
        this.props.growl({
          type: 'success',
          message:
            'Thank you for applying for our giveway contest.  If you win, we will notify you by email.',
        });
        this.setState({ formVisible: false });
      })
      .catch(err => {
        console.error('[giveawayWidget.js] ERROR submitting giveaway contact.');
        console.error(err);
        if (err.response.status === 409) {
          this.props.growl({
            type: 'danger',
            message: `It seems that you are already registered for this giveaway.  We will email you if you're a winner!`,
          });
        } else {
          this.props.growl({
            type: 'danger',
            message:
              'There was an error submitting your contact details.  Please try again or give us a call and we will manually add you to the drawing.',
          });
        }
      });
  };

  render() {
    const { visible, onClose } = this.props;
    return (
      <ThemeProvider theme={theme}>
        <div className="giveaway-widget">
          <Wrapper visible={visible} formVisible={this.state.formVisible}>
            <div className="giveaway-widget__cta-content">
              <div className="giveaway-widget__cta-content__image">
                <SVG image="close" onClick={onClose} />
                <SVG image="currency-dollar" />
              </div>
              <h3>
                Want to win $150 worth of cleaning services aboslutely free?
              </h3>
              <p>
                Register to win below. At our next drawing, we'll send you an
                email if you're the winner. Then we can schedule an appointment
                with you to redeem your $150 in cleaning services!
              </p>
              <button
                onClick={() => {
                  this.setState({ formVisible: true });
                  onClose();
                }}
              >
                Register to win
              </button>
            </div>
          </Wrapper>
          {this.state.formVisible && (
            <Modal
              header="Register to win $150 in free cleaning services"
              onClose={() => {
                this.setState({ formVisible: false });
              }}
            >
              <p>
                Enter your email address below and click the{' '}
                <strong>register</strong> button to complete your entry.
              </p>
              <FormWrapper>
                <form>
                  <EmailInput label="Email" />
                  <button
                    onClick={event => {
                      event.preventDefault();
                      const widget = document.querySelector('.giveaway-widget');
                      const emailInput = widget.querySelector('input');
                      const emailAddress = emailInput.value;

                      this.submitContact({ email: emailAddress });
                    }}
                  >
                    Register to win
                  </button>
                </form>
              </FormWrapper>
              <p>
                No purchase is necessary to win the prize. To be eligible for
                the prize, the service location must be within the New River
                Valley or Roanoke in Virginia. This includes Blacksburg,
                Christiansburg, Radford, Roanoke, Salem, and surrounding areas.
              </p>
            </Modal>
          )}
        </div>
      </ThemeProvider>
    );
  }
}

const Wrapper = Styled.div`
  position: fixed;
  top: 100%;
  left: 24px;
  left: auto;
  right: 100%;
  transition: all .4s ease;
  background: #FDFDFD;
  padding: 12px;
  transform: ${({ visible }) =>
    visible
      ? 'translateY(-100%) translateY(-24px) translateX(100%) translateX(24px)'
      : 'none'};
  border-radius: 4px;
  box-shadow: 0 1px 8px rgba(0, 0, 0, .25);
  /* max-width: 280px;
  min-width: 240px; */
  width: 280px;
  z-index: 10002;
  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }

  .giveaway-widget__cta-content {
    .svg__close {
      width: 18px;
      height: 18px;
      fill: white;
      cursor: pointer;
      position: absolute;
      top: 9px;
      right: 9px;
    }

    p {
      line-height: 1.4;
    }

    button {
      border: none;
      background: ${({ theme }) => theme.primary.colors.orange.base};
      border-radius: 4px;
      color: white;
      padding: 12px 24px;
      cursor: pointer;
      transition: all .3s ease;
      width: 100%;
    }

    button:hover {
      background: ${({ theme }) => theme.primary.colors.orange.lightest};
    }
  }

  .giveaway-widget__cta-content__image {
    width: 100%;
    height: 100px;
    background-color: ${({ theme }) => theme.primary.colors.orange.base};
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  .giveaway-widget__cta-content__image .svg__currency-dollar {
    fill: white;
    width: 48px;
    height: 48px;
  }
`;

const FormWrapper = Styled.div`
  form button {
    border: none;
    background: ${({ theme }) => theme.primary.colors.orange.base};
    border-radius: 4px;
    color: white;
    padding: 12px 24px;
    cursor: pointer;
    transition: all .3s ease;
  }
`;

const EmailInput = ({ label }) => {
  return (
    <EmailInputWrapper>
      <label>
        <div>{label}</div>
        <div className="email-input__input-wrapper">
          <input type="email" />
          <SVG image="envelope" />
        </div>
      </label>
    </EmailInputWrapper>
  );
};

const EmailInputWrapper = Styled.div`
  width: 240px;
  box-sizing: border-box;
  margin-bottom: 12px;

  * {
    box-sizing: border-box;
  }

  label {
    display: block;
  }

  label div {
    margin-bottom: 6px;
  }

  input {
    border-radius: 2px;
    padding: 6px 30px 6px 12px;
    border: none;
    box-shadow: 0 1px 4px rgba(0,0,0,.15);
    width: 100%;
  }

  .email-input__input-wrapper {
    position: relative;
  }

  .svg__envelope {
    position: absolute;
    width: 15px;
    height: 15px;
    fill: #999;
    right: 6px;
    top: 50%;
    transform: translateY(-50%);
  }
`;

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    growl: ({ message, type }) => dispatch(growl({ message, type })),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GiveawayWidget);
