import React from 'react';
import Styled from 'styled-components';

import { ThemeProvider } from 'styled-components';

import Layout from '../../layouts/blogLayoutCarpetCleaning';

import theme from '../../styles/theme';

import { baseMixin } from '../../styles/mixins';

export default function CarpetBuyingGuide() {
  return (
    <ThemeProvider theme={theme}>
      <Layout>
        <Wrapper>
          <h1>The Ultimate Carpet Buying Guide</h1>
          <p style={{ fontSize: '90%', color: '#777' }}>December 20th, 2019</p>
          <p>Making any good purchasing decision is hard.</p>
          <p>
            Buying carpet is no different. You must consider many factors when
            buying carpet. Most of these factors will not be thoroughly
            discussed with the sales people at a home improvement store.
          </p>
          <p>
            In this guide, we are going to consider the types of carpet fiber
            (and which is right for you). We will also be discussing four key
            aspects that you should consider when making a carpet buying
            decision:
          </p>
          <ul>
            <li>Quality of look and feel</li>
            <li>Longevity</li>
            <li>Maintainability</li>
            <li>Affordability</li>
          </ul>
          <p>
            These are all very important qualities to consider when buying
            carpet. With some careful consideration, you should be able to find
            a quality carpet within your price range.
          </p>
          <h2>Let's start with some key terms</h2>
          <p>
            There are some terms that you may come across during your research.
            Some of these will be used throughout this guide as well. Before we
            dive into the guide, let’s introduce these terms in the context of
            carpet fibers.
          </p>
          <p>
            <strong>Hydrophobic</strong>. This means that the carpet fiber
            doesn’t absorb moisture. Fibers that don’t absorb moisture often
            resist staining as well.
          </p>
          <p>
            <strong>Oleophilic</strong>. This means that the carpet fibers
            attract oils. Fibers that have this property will be susceptible to
            oily and greasy spots and spills.
          </p>
          <p>
            <strong>Abrasion</strong>. This refers to the scarring of the sides
            of the carpet fibers. Carpet that has significant abrasion will
            often look worn and dingy. Many confuse this with carpet wear or
            dirty carpet. But this is a form of carpet damage and can’t be
            corrected once it has occurred. Some types of fibers are more
            susceptible to abrasion than others.
          </p>
          <p>
            <strong>Resiliency</strong>. This refers to a fiber’s ability to
            return to its normal position after it has been matted or altered in
            some way. Carpet fibers with a high resiliency will resist long-term
            furniture indentations and matting.
          </p>
          <p>
            <strong>Denier</strong>. This refers to the diameter of fiber strand
            (its width). Thinner can be softer, but won’t wear as well.
          </p>
          <h2>Onward to fiber types</h2>
          <h3>Nylon</h3>
          <p>
            Nylon is the most common fiber type used in carpet. This is no
            coincidence. Nylon carpets may very well be the best type of carpet.
            When considering most carpet fibers, you will notice that they have
            pros and cons. Nylon, however, has no weaknesses (except cost).
          </p>
          <p>
            While nylon isn’t naturally stain resistant, modern nylon carpets go
            through a stain-resistance carpet treatment. This gives them
            significant protection from spills. It’s still important to clean up
            a spill quickly. But if you do, odds are it will come out.
          </p>
          <p>
            Nylon is a synthetic fiber invented by DuPont. It accepts color
            well. This means that nylon carpets can be made available in a wide
            variety of color choices. It is also a very durable carpet fiber.
            Carpets made from nylon are likely to last longer than carpets made
            of nearly any other fiber.
          </p>
          <p>
            If you read the key terms above, you would have seen resiliency.
            Resiliency is the ability of a fiber to return to its original shape
            after it has been altered. Nylon fibers have a high resiliency. This
            adds to their durability and reduces the odds that furniture
            indentations will permanently affect the carpet.
          </p>
          <p>
            If you aren’t sufficiently sold on the quality of nylon, I’ll add a
            final point. Nylon carpets respond the best to carpet cleaning. This
            means that you can assume higher quality results from cleaning when
            you have a nylon carpet than you would be able to with other carpet
            types.
          </p>
          <p>
            With all of these benefits, expect to pay more for a nylon carpet
            than any other fiber type (except wool).
          </p>
          <h3>Olefin (polypropylene)</h3>
          <p>
            Olefin is perhaps the second most common fiber type behind nylon.
            You are most likely to find olefin carpets in commercial or outdoor
            settings. It is a synthetic fiber that is quite common in berber
            (loop pile) carpets.
          </p>
          <p>
            Olefin fibers are hydrophobic. As mentioned above, this means that
            they resist absorbing moisture. Because of this, olefin is very
            difficult to stain. Most spills are easily removable. This even
            includes red stains such as Kool-Aid or wine.
          </p>
          <p>
            Due to olefin being hydrophobic, color must be put into the fibers
            through a process called solution dyeing. This makes the color dyes
            an integral part of the fiber. Because of this, colors aren’t likely
            to fade. It also limits the color options, however.
          </p>
          <p>Olefin is often the least expensive carpet fiber.</p>
          <p>
            Given all of these advantages, olefin has many disadvantages as
            well.
          </p>
          <ul>
            <li>It is a weaker fiber type.</li>
            <li>It is prone to abrasion and wear.</li>
            <li>
              It has very low resiliency (indentations don’t return to normal).
            </li>
            <li>It looks dingy very easily.</li>
            <li>
              It has a low temperature melting point. This means that even
              friction from sliding a heavy piece of furniture across the carpet
              can sometimes cause scarring.
            </li>
            <li>
              It is very difficult to clean. Results from carpet cleaning will
              often be unsatisfactory.
            </li>
            <li>It likely won’t last long.</li>
          </ul>
          <p>
            As you can see, olefin has many disadvantages. The use cases for
            olefin are very narrow, and unless you are buying carpet for a
            commercial or outdoor situation, it’s probably not the right choice
            for you.
          </p>
          <h3>Polyester</h3>
          <p>
            Polyester can be easily confused with olefin. Carpets made from
            these two fibers often look very similar. They also share many of
            the same properties.
          </p>
          <p>
            Perhaps the two biggest differences between polyester and olefin
            are:
          </p>
          <ul>
            <li>Polyester is a bit more expensive.</li>
            <li>Polyester is softer.</li>
          </ul>
          <p>
            There really aren’t any good use cases for polyester. If you are
            considering this fiber type, you’re probably better off with a
            cheaper olefin carpet. In the event that the softness is a selling
            point to you, you will definitely want to buy a carpet made from a
            different fiber.
          </p>
          <h3>Acrylic</h3>
          <p>
            Acrylic is an uncommon fiber type. It is a synthetic fiber that
            mimics the properties of wool. It is made from acrylonitrile and is
            much cheaper than wool. Acrylic carpets can be found under the brand
            names Acrilan, Orlon and Creslan.
          </p>
          <p>
            Acrylic is hydrophobic and resists soiling, staining, static, and
            mildew. It also resists fading and is easier to clean than wool.
          </p>
          <p>
            On the flip side, acrylic is less durable and has a lower resiliency
            than wool. Oils and grease can stain acrylic very easily.
          </p>
          <p>
            Sometimes you will find acrylic in a blend with wool. This will make
            the wool/acrylic blend cheaper than a plain wool carpet. Generally
            speaking, though, you won’t see a lot of acrylic carpets on the
            market.
          </p>
          <h3>Blends</h3>
          <p>Blends are generally either:</p>
          <ul>
            <li>A mix of nylon and olefin.</li>
            <li>A mix of nylon and polyester.</li>
            <li>A mix of acrylic and wool.</li>
            <li>A mix of wool and nylon.</li>
          </ul>
          <p>
            There is no real benefit to purchasing a blend. They exist only to
            provide nylon and wool carpets at a slightly cheaper price. Be
            careful when buying a cheaper nylon or wool carpet as you might be
            getting sucked into buying a blend.
          </p>
          <h3>Wool</h3>
          <p>
            Wool is the only natural fiber to make this list of fiber types.
            While still very uncommon, you are more likely to find a wool carpet
            than any other natural fiber carpet.
          </p>
          <p>
            Wool is an expensive carpet fiber. It is very soft, fire resistant,
            and very high quality.
          </p>
          <p>
            It is also susceptible to moth damage and can be difficult (and
            risky) to clean and maintain.
          </p>
          <p>
            When having wool carpets professionally cleaned, always notify your
            cleaning professional ahead of time. Most of the cleaning solutions
            that carpet cleaners use are not wool-safe. They will need to bring
            a wool-safe cleaning solution with them.
          </p>
          <h3>Triexta</h3>
          <p>
            Triexta (often called PTT) is typically seen under the brand names
            Sorona by DuPont or SmartStrand by Mohawk Industries.
          </p>
          <p>
            Triexta is the newest common fiber type on the market and seems to
            be one of the best available. Like nylon, triexta was developed by
            DuPont.
          </p>
          <p>
            Triexta looks like nylon but it is softer than nylon. It naturally
            resists stains, fading, and is very durable. It is hydrophobic
            (doesn’t absorb moisture), but it is oleophilic (attracts oils). It
            also is naturally stain resistant and unlike nylon doesn’t require
            any chemicals to resist stains.
          </p>
          <p>
            To add to the list of advantages of triexta, it’s also generally
            cheaper than nylon. The only real disadvantages are the fact that
            it’s oleophilic and may not be well suited for heavy foot traffic
            conditions.
          </p>
          <h3>Recommendation</h3>
          <p>
            Generally speaking, I believe the best choices are either nylon or
            triexta. I would probably lean towards nylon, but the lower price of
            triexta might be a nice selling point for you.
          </p>
          <p>
            Wool carpets are also very nice. But their high price point and
            maintenance difficulties can be a turn-off.
          </p>
          <p>
            I would only suggest olefin in a commercial or outdoor setting when
            you are prepared to need to replace it after just a few years. I
            would never recommend polyester.
          </p>
          <h2>Carpet construction</h2>
          <p>
            After you consider the fiber type of your new carpet, you need to
            consider the construction. There are several types of carpet
            construction that are at least somewhat common.
          </p>
          <h3>Berber (loop pile)</h3>
          <p>
            A berber carpet is a carpet where the fibers are looped over on
            themselves. Instead of having individual fibers sticking up from the
            carpet, you have loops.
          </p>
          <p>
            In some berber designs, these loops will be very tight. This makes
            the carpet almost feel knotted. Other designs will have loose loops
            that you can place your fingers through.
          </p>
          <p>
            Berber construction can sometimes hide carpet wear. This makes it a
            popular choice in commercial settings where traffic can be high.
            It’s a less common choice in people’s homes though.
          </p>
          <h3>Standard (cut pile)</h3>
          <p>
            This is the most common carpet construction. There are no loops and
            the fibers tend to the shorter side. Fibers will often be an inch or
            less in length.
          </p>
          <h3>Shag</h3>
          <p>
            Groovy baby. Shag carpets were fairly popular in the 70s. Since
            then, their popularity has greatly declined.
          </p>
          <p>
            Shag carpets will often look like longer versions of standard carpet
            construction. The fibers are long. Sometimes they are several inches
            long even. Instead of standing upright, the fibers lie down.
          </p>
          <p>
            Shag carpets are extremely rare nowadays. If you see a carpet that
            appears to be shag, it’s probably <em>frieze</em> instead.
          </p>
          <h3>Frieze</h3>
          <p>
            Frieze carpet construction has tightly twisted carpet fibers that
            are longer than standard cut pile carpet fibers. It’s fairly easy to
            get frieze confused with shag, but they aren’t the same at all.
          </p>
          <p>
            Frize construction tends to be a bit more durable than standard cut
            pile and much more durable than shag. It can be a good choice for
            high traffic areas.
          </p>
          <p>The disadvantages of this construction type are:</p>
          <ul>
            <li>It tends to be slightly more expensive.</li>
            <li>You may not like the super-casual appearance.</li>
          </ul>
          <h3>Recommendation</h3>
          <p>
            Generally, I recommend sticking with a standard cut pile carpet
            type. If high traffic and heavy wear is a concern, you may want to
            consider frieze or berber.
          </p>
          <h2>Maintenance guidelines</h2>
          <p>
            After picking out and installing your new carpet, you need to
            consider how to maintain it. Maintaining the carpet correctly is
            very important if you want it to last a long time and to look its
            best.
          </p>
          <h3>Vacuum daily</h3>
          <p>
            It’s easy to skimp out on vacuuming. But vacuuming once a day is
            important for carpet longevity.
          </p>
          <p>
            Dirt in your carpet doesn’t just make the carpet dirty. It also
            causes more abrasion to occur. This will wear your carpet out and
            make it look dingy. You can counter this by vacuuming daily.
          </p>
          <h3>Move furniture around at least once every six months</h3>
          <p>
            If you leave the furniture in place for too long, the indentations
            can become permanent. This is even true with nylon, wool, or triexta
            carpets that have a high resiliency.
          </p>
          <p>
            Also, keeping the furniture in the same place will keep the traffic
            lanes consistent. This will cause uneven wear patterns on your new
            carpet.
          </p>
          <p>
            By moving the furniture around regularly, you can avoid both of
            these problems.
          </p>
          <h3>Treat spills and stains immediately</h3>
          <p>
            When you’re finally sitting down to a delicious dinner and a
            meatball falls off the top of your spaghetti mountain and lands on
            your new carpet, it’s a problem.
          </p>
          <p>
            You might be tempted to wait until you have finished eating before
            you clean it up (or just leave it to the dog). However, the longer
            that tomato-sauce covered meatball sits there, the greater the
            chance of a stain.
          </p>
          <p>
            You can reduce the chance of staining on your carpet by cleaning up
            spills quickly. Don’t let them sit long, and the chance of keeping
            the carpet stain free increases.
          </p>
          <h3>Clean the carpets at least once a year</h3>
          <p>
            Store-bought cleaners work pretty well. Professional cleaning is
            even better.
          </p>
          <p>
            By cleaning the carpets at least once a year, you help to keep the
            carpets clean and sanitary. You also keep them smelling good and
            reduce abrasion in your carpet.
          </p>
          <p>
            This is a crucial step to protect your carpets and help them last as
            long as possible.
          </p>
          <h2>Further reading and sources</h2>
          <ul>
            <li>
              <a href="https://shawfloors.com/flooring/how-to/carpet/how-it-s-made/comparing-carpet-fibers">
                https://shawfloors.com/flooring/how-to/carpet/how-it-s-made/comparing-carpet-fibers
              </a>
            </li>
            <li>
              <a href="https://www.thespruce.com/triexta-ptt-carpet-fiber-2908799">
                https://www.thespruce.com/triexta-ptt-carpet-fiber-2908799
              </a>
            </li>
            <li>
              <a href="https://www.carpetcaptain.com/carpet-buying-guide/nylon-carpet/">
                https://www.carpetcaptain.com/carpet-buying-guide/nylon-carpet/
              </a>
            </li>
            <li>
              <a href="https://www.carpetcaptain.com/carpet-buying-guide/frieze-carpet/">
                https://www.carpetcaptain.com/carpet-buying-guide/frieze-carpet/
              </a>
            </li>
            <li>
              <a href="http://www.carpetprofessor.com/new_page_23.htm">
                http://www.carpetprofessor.com/new_page_23.htm
              </a>
            </li>
            <li>
              <a href="https://home.howstuffworks.com/home-improvement/home-diy/flooring/choose-right-carpet-fiber5.htm">
                https://home.howstuffworks.com/home-improvement/home-diy/flooring/choose-right-carpet-fiber5.htm
              </a>
            </li>
          </ul>
        </Wrapper>
      </Layout>
    </ThemeProvider>
  );
}

const Wrapper = Styled.div`
  ${baseMixin}
`;
