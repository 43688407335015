import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import { Helmet } from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import Styled from 'styled-components';

import Header from '../components/header';
import Footer from '../components/footer';
import IE11Notification from '../components/ie11Notification';
import NotificationProvider from '../components/notificationProvider';
import LiveChat from '../components/liveChat';
import Tracker from '../components/tracker';
import TestimonialCard from '../components/testimonialCard';
import BeforeAfterImage from '../components/beforeAfterImage';
import GiveawayWidget from '../components/giveawayWidget/';

import usePresence from '../hooks/usePresence';
import useGiveawayWidget from '../hooks/useGiveawayWidget';

import config from '../config';

import '../util/normalize.css';

import {
  TestimonalSection,
  ThreeColumnCompare,
  BeforeAfterSection,
} from '../styles/pages/carpet-cleaning';

function Layout(props) {
  const [giveawayWidgetVisible, setGiveawayWidgetVisible] = useGiveawayWidget();
  const [anyOnline] = usePresence();
  const [visitorId, setVisitorId] = useState(null);

  const { children, hero } = props;

  return (
    <StaticQuery
      query={graphql`
        query BlogSiteMetaDataQuery {
          site {
            siteMetadata {
              title
              description
            }
          }
          cmgIcon: file(
            relativePath: { regex: "/trust-badges/logo-cmg.jpg/" }
          ) {
            childImageSharp {
              fixed(width: 113, height: 52) {
                ...GatsbyImageSharpFixed
              }
            }
          }
          bondurantIcon: file(
            relativePath: { regex: "/trust-badges/logo-bondurant.png/" }
          ) {
            childImageSharp {
              fixed(width: 105, height: 55) {
                ...GatsbyImageSharpFixed
              }
            }
          }
          bcrIcon: file(
            relativePath: { regex: "/trust-badges/logo-bcr.png/" }
          ) {
            childImageSharp {
              fixed(width: 113, height: 56) {
                ...GatsbyImageSharpFixed
              }
            }
          }
          terraceViewIcon: file(
            relativePath: { regex: "/trust-badges/logo-terrace-view.png/" }
          ) {
            childImageSharp {
              fixed(width: 135, height: 86) {
                ...GatsbyImageSharpFixed
              }
            }
          }
          longAndFosterIcon: file(
            relativePath: { regex: "/trust-badges/logo-long-and-foster.jpg/" }
          ) {
            childImageSharp {
              fixed(width: 150, height: 113) {
                ...GatsbyImageSharpFixed
              }
            }
          }
          foxridgeIcon: file(
            relativePath: { regex: "/trust-badges/logo-foxridge.png/" }
          ) {
            childImageSharp {
              fixed(width: 158, height: 47) {
                ...GatsbyImageSharpFixed
              }
            }
          }
          riceRealtyIcon: file(
            relativePath: { regex: "/trust-badges/logo-rice-realty.jpg/" }
          ) {
            childImageSharp {
              fixed(width: 113, height: 69) {
                ...GatsbyImageSharpFixed
              }
            }
          }
          sasIcon: file(
            relativePath: { regex: "/trust-badges/logo-sas.jpg/" }
          ) {
            childImageSharp {
              fixed(width: 124, height: 74) {
                ...GatsbyImageSharpFixed
              }
            }
          }
          beforeAfterOne: file(relativePath: { regex: "/cc-gallery-1.jpg/" }) {
            childImageSharp {
              fixed(width: 283, height: 159) {
                ...GatsbyImageSharpFixed
              }
              fluid(maxWidth: 1080) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          beforeAfterTwo: file(relativePath: { regex: "/cc-gallery-2.jpg/" }) {
            childImageSharp {
              fixed(width: 283, height: 159) {
                ...GatsbyImageSharpFixed
              }
              fluid(maxWidth: 1080) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          beforeAfterThree: file(
            relativePath: { regex: "/cc-gallery-3.jpg/" }
          ) {
            childImageSharp {
              fixed(width: 283, height: 159) {
                ...GatsbyImageSharpFixed
              }
              fluid(maxWidth: 1080) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          beforeAfterFour: file(relativePath: { regex: "/cc-gallery-4.jpg/" }) {
            childImageSharp {
              fixed(width: 283, height: 159) {
                ...GatsbyImageSharpFixed
              }
              fluid(maxWidth: 1080) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          beforeAfterFive: file(relativePath: { regex: "/cc-gallery-5.jpg/" }) {
            childImageSharp {
              fixed(width: 283, height: 159) {
                ...GatsbyImageSharpFixed
              }
              fluid(maxWidth: 1080) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      `}
      render={data => (
        <LayoutWrapper hero={hero}>
          <Helmet
            title={data.site.siteMetadata.title}
            meta={[
              {
                name: 'description',
                content: data.site.siteMetadata.description,
              },
            ]}
          >
            <html lang="en" />
            <meta name="application-name" content="Sci-Tech Carpet Cleaning" />
          </Helmet>
          <IE11Notification />
          <GiveawayWidget
            visitorId={visitorId}
            visible={giveawayWidgetVisible}
            onClose={() => setGiveawayWidgetVisible(false)}
          />
          <Header />
          {hero}
          <div className="main-layout__content-wrapper">
            {typeof children === 'function' ? (
              <Fragment>{children}</Fragment>
            ) : (
              <Fragment>{children}</Fragment>
            )}
          </div>
          <TestimonalSection>
            <h2>See What Our Clients Are Saying About Us</h2>
            <div className="testimonial-section__testimonials">
              <TestimonialCard>
                <blockquote>
                  <p>
                    I had very little expectation that this carpet could even
                    look halfway decent. I was getting it cleaned to help with a
                    mold issue in this old house we are renting. I had to be
                    gone for a day or so and during this time they came in to
                    clean the carpet. When I got back and opened the door, I
                    thought I was at the wrong house. It was amazing. They got
                    things out of the carpet that I thought were permanent.
                    Amazing results!
                  </p>
                  <footer>Anonymous</footer>
                </blockquote>
              </TestimonialCard>
              <TestimonialCard>
                <blockquote>
                  <p>
                    Very quick response to our request. Technician showed up
                    exactly on time; was very knowledgable, professional, and
                    pleasant. Work done quickly and looks great. Price very
                    reasonable - and how it was priced was clearly explained
                    before service contract agreed and at the time of payment.
                  </p>
                  <footer>Harris J.</footer>
                </blockquote>
              </TestimonialCard>
              <TestimonialCard>
                <blockquote>
                  <p>
                    Great Service! We were worked into a busy schedule on short
                    notice and the carpets looked great. Also very well priced.
                  </p>
                  <footer>Jason W.</footer>
                </blockquote>
              </TestimonialCard>
              <TestimonialCard>
                <blockquote>
                  <p>
                    Since I moved to northern Virginia I cannot find a carpet
                    company as good as you. Do you know of a good company near
                    Alexandria? I still own a townhouse in Christiansburg and
                    use your services every year. I appreciate the excellent
                    results! It is so hard to find a quality and honest company.
                  </p>
                  <footer>Melissa</footer>
                </blockquote>
              </TestimonialCard>
              <TestimonialCard>
                <blockquote>
                  <p>
                    I needed a large room with wall to wall carpeting cleaned,
                    also an area rug and a set of stairs. In addition, they took
                    away a wool area rug to be cleaned at their place of
                    business.
                  </p>
                  <p>
                    Very well. I was very happy with the results. I felt that
                    the fee was reasonable, though I don't remember exactly what
                    I paid at the time. The person I talked to when I made the
                    appointment, Michael, was very helpful and answered my
                    questions and concerns regarding pet odor on the wall to
                    wall carpeting. I will call them again when the carpets need
                    cleaning.
                  </p>
                  <footer>Anonymous</footer>
                </blockquote>
              </TestimonialCard>
              <TestimonialCard>
                <blockquote>
                  <p>
                    Sci-Tech saved me thousands on carpet replacement. Who would
                    have thought that purple nail polish could come out of white
                    carpet. I thank you and my daughter thanks you.
                  </p>
                  <footer>Nancy</footer>
                </blockquote>
              </TestimonialCard>
            </div>
          </TestimonalSection>
          <div className="blog-layout__lp">
            <section>
              <h2>Our Standard Packages</h2>
              <ThreeColumnCompare>
                <div>
                  <h4>Basic cleaning</h4>
                  <ul>
                    <li>Hot water extraction (steam cleaning)</li>
                    <li>Use of industry-standard wand tool</li>
                    <li>Basic spot/stain removal included</li>
                    <li>Moving of small/simple items included</li>
                    <li>6 to 12 hour estimated drying time</li>
                    <li>Recommended in most situations</li>
                  </ul>
                </div>
                <div>
                  <h4>Low moisture cleaning</h4>
                  <ul>
                    <li>Very-low-moisture cleaning</li>
                    <li>Use of specialized equipment for drier cleaning</li>
                    <li>Basic spot/stain removal included</li>
                    <li>Moving of small/simple items included</li>
                    <li>2 to 4 hour estimated drying time</li>
                    <li>Recommended when drying time is a concern</li>
                  </ul>
                </div>
                <div>
                  <h4>Deep cleaning</h4>
                  <ul>
                    <li>Hot water extraction (steam cleaning)</li>
                    <li>Use of specialized rotary scrubbing tool</li>
                    <li>Heavy traffic lane cleaning</li>
                    <li>Basic spot/stain removal included</li>
                    <li>Moving of small/simple items included</li>
                    <li>6 to 12 hour estimated drying time</li>
                    <li>
                      Recommended when the carpets are extra dirty or haven't
                      been cleaned in several years
                    </li>
                  </ul>
                </div>
              </ThreeColumnCompare>
            </section>
            <section className="compressed">
              <h2>We also offer a green-clean package!</h2>
              <p>
                Our standard cleaners are very environmentally and pet friendly.
                In the situations where you want to be extra kind to the
                environment, however, we offer a green-clean package.
              </p>
              <p>
                In this package, we use only environmental alternatives to our
                most popular cleaners. These cleaners substitute harsher
                ingredients for those that are milder alternatives. While our
                most popular cleaners are the strongest and best that we use,
                the environmental alternatives are extremely good as well. They
                can get the job done in all but the toughest situations.
              </p>
            </section>
            <section className="compressed">
              <h2>
                We provide services for these fine companies (among others) as
                well as our residential clients.
              </h2>
              <div className="trust-badges">
                <Img
                  className="trust-badge"
                  fixed={data.cmgIcon.childImageSharp.fixed}
                />
                <Img
                  className="trust-badge"
                  fixed={data.bondurantIcon.childImageSharp.fixed}
                />
                <Img
                  className="trust-badge"
                  fixed={data.bcrIcon.childImageSharp.fixed}
                />
                <Img
                  className="trust-badge"
                  fixed={data.terraceViewIcon.childImageSharp.fixed}
                />
                <Img
                  className="trust-badge"
                  fixed={data.longAndFosterIcon.childImageSharp.fixed}
                />
                <Img
                  className="trust-badge"
                  fixed={data.foxridgeIcon.childImageSharp.fixed}
                />
                <Img
                  className="trust-badge"
                  fixed={data.riceRealtyIcon.childImageSharp.fixed}
                />
                <Img
                  className="trust-badge"
                  fixed={data.sasIcon.childImageSharp.fixed}
                />
              </div>
            </section>
            <section className="compressed">
              <h2>We have the experience</h2>
              <p>
                With nearly 20 years of experience, we have been a leading
                provider of carpet cleaning services in Blacksburg,
                Christiansburg, Radford, Roanoke, Salem, and the rest of the New
                River Valley for some time.
              </p>
              <p>
                If you open the phone book and look for carpet cleaners, you'll
                likely find more than a dozen available. Few of these have even
                half of the experience that we have dealing with the challenges
                that cleaners face.
              </p>
              <p>Our experience has helped our ability to:</p>
              <ul>
                <li>
                  Thoroughly clean carpets without causing any damage to the
                  sensitive fibers.
                </li>
                <li>Remove tough stains that other cleaners leave behind.</li>
                <li>Clean carpets fully without leaving them too wet.</li>
                <li>
                  Remove odors from the carpets leaving them smelling fresh and
                  clean.
                </li>
                <li>
                  Remove allergens from the carpets helping you to breathe
                  easier.
                </li>
              </ul>
            </section>
            <BeforeAfterSection>
              <h2>Check Out A Few Before / After Pictures</h2>
              <div className="before-after-section__inner-wrapper">
                <BeforeAfterImage
                  thumbnail={
                    <Img fixed={data.beforeAfterOne.childImageSharp.fixed} />
                  }
                  image={
                    <Img fluid={data.beforeAfterOne.childImageSharp.fluid} />
                  }
                >
                  <p>
                    This berber carpet was quite filthy. By pre-treating it
                    before cleaning, we were able to remove nearly all of the
                    dirt in the carpet.
                  </p>
                </BeforeAfterImage>
                <BeforeAfterImage
                  thumbnail={
                    <Img fixed={data.beforeAfterTwo.childImageSharp.fixed} />
                  }
                  image={
                    <Img fluid={data.beforeAfterTwo.childImageSharp.fluid} />
                  }
                >
                  <p>
                    This is a different angle of the same carpet in the image
                    before. This carpet was cleaned using our basic cleaning
                    package.
                  </p>
                </BeforeAfterImage>
                <BeforeAfterImage
                  thumbnail={
                    <Img fixed={data.beforeAfterThree.childImageSharp.fixed} />
                  }
                  image={
                    <Img fluid={data.beforeAfterThree.childImageSharp.fluid} />
                  }
                >
                  <p>
                    See the difference in this carpet? This is a thick and cream
                    colored nylon carpet. It looked like new after cleaning.
                  </p>
                </BeforeAfterImage>
                <BeforeAfterImage
                  thumbnail={
                    <Img fixed={data.beforeAfterFour.childImageSharp.fixed} />
                  }
                  image={
                    <Img fluid={data.beforeAfterFour.childImageSharp.fluid} />
                  }
                >
                  <p>
                    This was a large basement carpet that we cleaned. The
                    outside edges of the picture are the areas we've already
                    cleaned. Notice how much brighter the carpet looks.
                  </p>
                </BeforeAfterImage>
                <BeforeAfterImage
                  thumbnail={
                    <Img fixed={data.beforeAfterFive.childImageSharp.fixed} />
                  }
                  image={
                    <Img fluid={data.beforeAfterFive.childImageSharp.fluid} />
                  }
                >
                  <p>
                    This is a picture of our deluxe package on display. The
                    swirl marks in the carpet are from the rotary machine. The
                    dark traffic areas in this carpet are being completely
                    removed.
                  </p>
                </BeforeAfterImage>
              </div>
            </BeforeAfterSection>
          </div>
          <Footer />
          {anyOnline && config.backendActive && (
            <LiveChat visitorId={visitorId} />
          )}
          {config.backendActive && (
            <Tracker
              onGetVisitorId={visitorId => {
                setVisitorId(visitorId);
              }}
            />
          )}
          <NotificationProvider />
        </LayoutWrapper>
      )}
    />
  );
}

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
};

const LayoutWrapper = Styled.div`
  font-family: lato, sans-serif;
  font-size: 18px;
  
  .main-layout__content-wrapper {
    width: 750px;
    margin: ${({ hero }) => (hero ? '0 auto' : '60px auto')};
    padding: 0px 1.0875rem 1.45rem;
    padding-top: 50px;

    h1.underlined, h2.underlined, h3.underlined, h4.underlined {
      &:after {
        display: block;
        content: "";
        border-bottom: 2px solid #E96656;
        width: 150px;
        margin: 10px 0 20px 0;
      }
    }
  }

  .blog-layout__lp {
    padding-left: 24px;
    padding-right: 24px;
    font-size: 18px;
    font-family: lato, sans-serif;
    line-height: 1.4;
  }

  .blog-layout__lp h2 {
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1.2px;
  }

  .blog-layout__lp section {
    margin-top: 96px;
    margin-bottom: 96px;

    &.compressed {
      max-width: 720px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .blog-layout__lp .trust-badges {
    display: inline-flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .trust-badge {
      margin: 24px;
    }
  }

  @media screen and (max-width: 900px) {
    .main-layout__content-wrapper {
      width: 600px;
    }
  }

  @media screen and (max-width: 700px) {
    .main-layout__content-wrapper {
      width: 540px;
    }
  }

  @media screen and (max-width: 600px) {
    .main-layout__content-wrapper {
      width: 460px;
    }
  }

  @media screen and (max-width: 500px) {
    .main-layout__content-wrapper {
      width: 90%;
    }
  }
`;

export default Layout;
